import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Typography,
  CardHeader,
  CardContent,
  Stack,
  Button,
  Box,
  Divider,
  Avatar,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import errorHandler from "../../utils/errorHandler";
import CardLayout from "../../layouts/CardLayout";
import { Icon } from "@iconify/react";
import StatusBTN from "../../components/StatusBTN";
import apiOfferBannerManagement from "../../services/offerandbannerservices";
import { IMAGE_BASE } from "../../utils/constants";
import { fDateTime } from "../../utils/formatTime";
import { capitalizeFirstLetter } from "../../utils/helpers";
import ApiService from "../../services/categoryManagementApi";

const { productManagementApi } = ApiService;

const ProductView = () => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await productManagementApi.getById({ id: id });
        setData(response?.data?.data);
      } catch (error) {
        errorHandler(error);
      }
    };

    fetchData();
  }, [id]);

  const handleEdit = () => {
    navigate(`/offer-and-banner-management/edit/${id}`);
  };

  const handleback = () => {
    navigate(`/offer-and-banner-management/`);
  };
  return (
    <Box>
      <Helmet>
        <title>Product Management</title>
      </Helmet>
      <CardLayout>
        <CardHeader title="Product View" />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ boxShadow: 4, borderRadius: 3, overflow: "hidden" }}>
                <CardContent>
                  <Box display="flex" justifyContent="center" mb={2}>
                    <Avatar
                      src={IMAGE_BASE + data?.image} // Assuming this is the profile image
                      alt="Profile Icon"
                      sx={{ width: 100, height: 100 }} // Avatar size
                    />
                  </Box>

                  <Stack spacing={2}>
                    <Typography variant="h6">Name</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.name || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Category</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.categoryId?.name || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Price</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.price || ""}
                    </Typography>
                    <Divider></Divider>

                    <Typography variant="h6">Quantity</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.qty || ""}
                    </Typography>
                    <Divider></Divider>

                    {data?.categoryId?.name == "Cylinder" ? (
                      <>
                        <Typography variant="h6">Weight</Typography>
                        <Typography
                          variant="body1"
                          sx={{ overflowWrap: "break-word" }}
                        >
                          {data?.qty || ""}
                        </Typography>
                        <Divider></Divider>
                      </>
                    ) : null}
                    <Typography variant="h6">Description</Typography>
                    <Typography
                      variant="body1"
                      sx={{ overflowWrap: "break-word" }}
                    >
                      {data?.description || ""}
                    </Typography>
                    <Divider></Divider>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

export default ProductView;
