import * as Yup from "yup";
import {
  EMAIL_REGEX,
  MOBILE_REGEX,
  NOSPACE_REGEX,
} from "./constants";
import { MESSAGE } from "./validationMessage";


let title = Yup.string()
  .max(60, "This field should not be longer than 60 characters")
  .required(MESSAGE.REQUIRED)
  .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE);

let status = Yup.mixed().required(MESSAGE.REQUIRED);


export const CmsSchema = Yup.object({
  title,
  status,
  slug: Yup.string()
    .max(30, "Slug should not be longer than 30 characters")
    .required(MESSAGE.REQUIRED)
    .matches(NOSPACE_REGEX, MESSAGE.NO_SPACE),

});

export const bannerAndOffer = (id) => Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .min(3, 'Banner name is too short. It must be at least 3 characters long.')
    .max(100, 'Banner name is too long. It cannot exceed 100 characters.')
    .matches(/^\S/, "Name cannot start with a blank space"),
  
    startDate: Yup.date()
    .test('required-if-no-id', 'Start Date is required', function (value) {
      const { path, createError } = this;
      if (!id) {
        if (!value) {
          return createError({ path, message: 'Start Date is required' });
        }
        const currentDate = new Date();
        if (new Date(value) < currentDate) {
          return createError({ path, message: 'Start Date cannot be in the past' });
        }
      }
      return true;
    }),

  endDate: Yup.date()
    .test('required-if-no-id', 'End Date is required', function (value) {
      const { path, createError } = this;
      const { startDate } = this.parent;
      if (!id) {
        if (!value) {
          return createError({ path, message: 'End Date is required' });
        }
        // Check if endDate is at least 30 minutes after startDate
        const startTime = new Date(startDate);
        const endTime = new Date(value);
        if (endTime <= startTime || (endTime - startTime) < 30 * 60 * 1000) {
          return createError({ path, message: 'End Date must be at least 30 minutes after Start Date' });
        }
      }
      return true;
    }),


  
  offerType: Yup.string()
    .required('Offer Type is required'),

  couponCode: Yup.string()
    .min(6, 'Coupon Code should be at least 6 characters long')
    .max(8, 'Coupon Code should be at most 8 characters long')
    .matches(/^[A-Za-z0-9]*$/, 'Coupon Code can only contain letters and numbers, no spaces or special characters')
    .test('couponCode', 'Coupon Code is required', function(value) {
      const { offerType } = this.parent; 
      if (offerType === 'offer') {
        return value && value.trim() !== '';
      }
      return true;
    }),

  couponType: Yup.string()
    .test('couponType', 'Coupon Type is required', function(value) {
      const { offerType } = this.parent; 

      if (offerType === 'offer') {
        return value && value.trim() !== '';
      }
      return true;
    }),

  discount: Yup.string()
    .test('discount', 'Discount is required', function(value) {
      const { couponType } = this.parent; 
      if (couponType === 'percentage') {
        return value && value.trim() !== '';
      }
      return true;
    })
    .matches(/^[1-9]\d*$/, 'Discount must be a valid number without leading zeros or repeated digits')
    .test('isValidNumber', 'Discount must be between 0 and 100', function(value) {
      const { couponType } = this.parent; 
      if (couponType === 'percentage') {
        const numValue = parseInt(value, 0);
        return numValue >= 0 && numValue <= 100;
      }
      return true;

  }),

  minimumPrice: Yup.string()
    .test('minimumPrice', 'Minimum Price is required', function(value) {
      const { couponType } = this.parent; 
      if (couponType === 'percentage') {
        return value && value.trim() !== '';
      }
      return true;
    })
    .matches(/^[1-9]\d*$/, 'Minimum price must be a valid number without leading zeros or repeated digits')
    .test('isValidNumber', 'Minimum price must be between 10 and 100', function(value) {
      const { couponType } = this.parent; 
      if (couponType === 'percentage') {
        const numValue = parseInt(value, 10);
        return numValue >= 10 && numValue <= 100;
      }
      return true;

    }),

  purchaseProductIds: Yup.array()
    .of(Yup.string().required('You must select a Purchase Product'))
    .test('purchaseProductIds', 'You must select a Purchase Product', function(value) {
      const { couponType } = this.parent; 
      if (couponType === 'B1G1' || couponType === 'B1GO') {
        return value && value.length > 0; 
      }
      return true;
  }),

  freeProductId: Yup.mixed()
    .test('freeProductId', 'You must select a Free Product', function(value) {
      const { couponType } = this.parent;
      if (couponType === 'B1GO') {
        if (!value || (Array.isArray(value) && value.length === 0)) {
          return false;
        }
        return Array.isArray(value) && value.length > 0 && typeof value[0] === 'string' && value[0].trim().length > 0;
      }
      return true;
  }),

  image: Yup.mixed()
    .test('image', 'Image is required', function(value) {
      const imagePreview = this.parent.imagePreview;
      return imagePreview || value != null; 
    }),
});


export const category = Yup.object().shape({
  name: Yup.string()
    .required('Name is required')
    .min(3, 'Name should be at least 3 characters long')
    .max(50, 'Name should be at most 50 characters long')
    .matches(/^\S/, "Name cannot start with a blank space"),

  image: Yup.mixed()
    .test('image', 'Image is required', function(value) {
      const imagePreview = this.parent.imagePreview;
      return imagePreview || value != null; 
    }),
})

export const productValidation = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .trim()
    .test("name", "Name should be less than 100 characters", (values) => {
      if (values.length >= 100) {
        return false;
      } else {
        return true;
      }
    }),
  qty: Yup.string().required("Quantity is required"),
  weight: Yup.string().test(
    "selectedCategoryName",
    "Weight is required",
    (value, context) => {
      if (context.parent.selectedCategoryName == "Cylinder") {
        return value !== undefined && value !== "";
      }
      return true;
    }
  ),
  price: Yup.number()
    .required("Price is required")
    .min(1, "Price must be greater than 0"),
  image: Yup.mixed().required("Product image is required"),
  description: Yup.string().required("Description is required").trim(),
  categoryId: Yup.string().required("Category is required"),
})

export const dealerSchema = Yup.object().shape({
  businessName: Yup.string()
    .required("Business name is required")
    .min(3, "Business name must be at least 3 characters"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format"),
  phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(
      /^[0-9]{10}$/,
      "Phone number must be 10 digits long and contain only numbers"
    ),
  // image: Yup.mixed()
  //   .required("Image is required")
  //   .test("fileType", "Unsupported file format", (value) => {
  //     return !value || ["image/jpeg", "image/png", "image/gif"].includes(value.type);
  //   }),
    documents: Yup.mixed()
    .test("required", "Document is required", (value) => {
      return value !== null && value !== "";
    })
    
});

export const CustomerSchema = Yup.object().shape({
  name: Yup.string().required('Name is required')
  .max(55, "Name should not be longer than 55 characters")
  .trim()
  .min(2, "Name must be at least 2 characters"),
  email: Yup.string()
  .required()
    .matches(EMAIL_REGEX,'Invalid email format'),
  
  phoneNumber: Yup.string().matches(MOBILE_REGEX, 'Phone number is not valid').required('Phone is required'),
  registrationNumber: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, 'ID No./Registration No. should only contain alphanumeric characters')
    .min(6, 'ID No./Registration No. should be between 6-10 characters')
    .max(10, 'ID No./Registration No. should be between 6-10 characters')
    .required('ID No./Registration No. is required'),

    flatNumber: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-\/]+$/, 'Flat No./Plot No. should contain only alphanumeric characters, spaces, hyphens, or slashes')
    .min(3, 'Flat No./Plot No. should be at least 3 characters long')
    .max(10, 'Flat No./Plot No. should be at most 10 characters long')
    .required('Flat No./Plot No. is required'),

  area: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-\']+$/, 'Area should contain only alphanumeric characters, spaces, hyphens, or apostrophes')
    .min(2, 'Area should be at least 2 characters long')
    .max(50, 'Area should be at most 50 characters long')
    .test('not-purely-numeric', 'Area cannot be purely numeric', (value) => isNaN(Number(value)))
    .required('Area is required'),

  locality: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-\']+$/, 'Locality should contain only alphanumeric characters, spaces, hyphens, or apostrophes')
    .min(2, 'Locality should be at least 2 characters long')
    .max(50, 'Locality should be at most 50 characters long')
    .test('not-purely-numeric', 'Locality cannot be purely numeric', (value) => isNaN(Number(value)))
    .test('no-trailing-spaces', 'Locality cannot start or end with spaces or special characters', (value) =>
      value ? !/^[\s\-']|[\s\-']$/.test(value) : true
    )
    .required('Locality is required'),

  landmark: Yup.string()
    .matches(/^[a-zA-Z0-9\s,\'\-]+$/, 'Landmark should contain only alphanumeric characters, spaces, commas, hyphens, or apostrophes')
    .min(3, 'Landmark should be at least 3 characters long')
    .max(100, 'Landmark should be at most 100 characters long')
    .test('not-purely-numeric', 'Landmark cannot be purely numeric', (value) => isNaN(Number(value)))
    .test('no-consecutive-spaces', 'Landmark cannot contain consecutive spaces', (value) =>
      value ? !/\s{2,}/.test(value) : true
    )
    .test('no-trailing-spaces', 'Landmark cannot start or end with spaces or special characters', (value) =>
      value ? !/^[\s,\'\-]|[\s,\'\-]$/.test(value) : true
    )
    .required('Landmark is required'),

  city: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-\']+$/, 'City should contain only alphanumeric characters, spaces, hyphens, or apostrophes')
    .min(2, 'City should be at least 2 characters long')
    .max(50, 'City should be at most 50 characters long')
    .test('not-purely-numeric', 'City cannot be purely numeric', (value) => isNaN(Number(value)))
    .test('no-consecutive-spaces', 'City cannot contain consecutive spaces', (value) =>
      value ? !/\s{2,}/.test(value) : true
    )
    .test('no-trailing-spaces', 'City cannot start or end with spaces or special characters', (value) =>
      value ? !/^[\s\-']|[\s\-']$/.test(value) : true
    )
    .required('City is required'),

  village: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-\']+$/, 'Village should contain only alphanumeric characters, spaces, hyphens, or apostrophes')
    .min(2, 'Village should be at least 2 characters long')
    .max(50, 'Village should be at most 50 characters long')
    .test('not-purely-numeric', 'Village cannot be purely numeric', (value) => isNaN(Number(value)))
    .test('no-consecutive-spaces', 'Village cannot contain consecutive spaces', (value) =>
      value ? !/\s{2,}/.test(value) : true
    )
    .test('no-trailing-spaces', 'Village cannot start or end with spaces or special characters', (value) =>
      value ? !/^[\s\-']|[\s\-']$/.test(value) : true
    )
    .required('Village is required'),

  state: Yup.string()
    .matches(/^[a-zA-Z0-9\s\-\']+$/, 'State should contain only alphanumeric characters, spaces, hyphens, or apostrophes')
    .min(2, 'State should be at least 2 characters long')
    .max(50, 'State should be at most 50 characters long')
    .test('not-purely-numeric', 'State cannot be purely numeric', (value) => isNaN(Number(value)))
    .test('no-consecutive-spaces', 'State cannot contain consecutive spaces', (value) =>
      value ? !/\s{2,}/.test(value) : true
    )
    .test('no-trailing-spaces', 'State cannot start or end with spaces or special characters', (value) =>
      value ? !/^[\s\-']|[\s\-']$/.test(value) : true
    )
    .required('State is required'),

  pinCode: Yup.string()
    .matches(/^[0-9]{6}$/, 'Pin Code should be 6 digits and contain only numbers')
    .required('Pin Code is required'),
});
