import {
  Grid,
  CardHeader,
  CardContent,
  Box,
  TextField,
  CircularProgress,
  Avatar,
  Button,
  Typography,
} from "@mui/material";
import apiOfferbannerManagement from "../../services/offerandbannerservices";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import errorHandler from "../../utils/errorHandler";
import { IMAGE_BASE } from "../../utils/constants";
import LoadingButton from "@mui/lab/LoadingButton";
import CardLayout from "../../layouts/CardLayout";
import { Helmet } from "react-helmet-async";
import { toast } from "react-toastify";
import { dealerSchema } from "../../utils/Schema";
import ApiService from "../../services/categoryManagementApi";

const { dealerManagementApi } = ApiService;

function AddDealer() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null); // New state for document
  const fileInputRef = useRef(null);
  const documentInputRef = useRef(null); // New ref for document input
  const { id } = useParams();
  const [data,setData] = useState()

  const fetchTaskData = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await dealerManagementApi.getById({ id });
        const resData = response?.data?.data;
        setImagePreview(IMAGE_BASE + resData?.image);

        setData(resData);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      businessName: data?.businessName || "",
      email: data?.email || "",
      phoneNumber: data?.phoneNumber || "",
      imagePath: "dealer",
      documents: data?.documents || "", 
    },
    validationSchema: dealerSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        if (id) {
          formData.append("id", id);
        }
        formData.append("businessName", values?.businessName);
        formData.append("email", values?.email)
        formData.append("phoneNumber", values?.phoneNumber)
        formData.append("imagePath", "dealer");
        if (selectedDocument) formData.append("documents", selectedDocument); // Append document

        const response = id
          ? await dealerManagementApi.updateDealer(formData)
          : await dealerManagementApi.add(formData, values);

        toast.success(response?.data?.message, { toastId: "success" });
        navigate(-1);

        fileInputRef.current.value = "";
        documentInputRef.current.value = ""; // Reset document input
        setSelectedImage(null);
        setSelectedDocument(null); // Reset document state
        setImagePreview(null);
      } catch (error) {
        console.log("error dealer",error);
        toast.error(error?.response?.data?.data?.message)
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    },
  });

 

  const handleDocumentChange = (event) => {
    const file = event.currentTarget.files[0];
    console.log("file",file);
    if (file) {
      setSelectedDocument(file);
      formik.setFieldValue("documents", file);
    }
  };

  return (
    <Box>
      <Helmet>
        <title>{id ? "Edit" : "Add"} Dealer</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={`${id ? "Edit" : "Add"} Dealer`} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
              
                <Grid item mt={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    required
                    value={formik.values.businessName}
                    onChange={formik.handleChange}
                    name="businessName"
                    error={Boolean(formik.touched.businessName && formik.errors.businessName)}
                    helperText={formik.touched.businessName && formik.errors.businessName}
                  />
                </Grid>
                <Grid item mt={6} xs={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    required
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name="email"
                    error={Boolean(formik.touched.email && formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    required
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    name="phoneNumber"
                    error={Boolean(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                  />
                </Grid>

                <Grid item xs={6}>
  <TextField
    fullWidth
    name="documents"
    label="Document"
    type="file"
    inputRef={documentInputRef}
    onChange={handleDocumentChange}
    InputLabelProps={{ shrink: true }}
    error={Boolean(formik.touched.documents && formik.errors.documents)}
    helperText={formik.touched.documents && formik.errors.documents}
  />
  {data?.documents?.[0] && (
    <Typography variant="body2" color="textSecondary" mt={1}>
      {data.documents[0]}
    </Typography>
  )}
</Grid>


                <Grid item mt={4} xs={6}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    {id ? "Edit" : "Create"} Dealer
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
}

export default AddDealer;
