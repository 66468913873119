import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, CardContent, Stack, TextField, Typography, CircularProgress } from "@mui/material";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { EMAIL_REGEX } from "../../utils/constants";
import apiUsers from "../../services/authServiceLogin";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";

const URL_REGEX = /^https:\/\/[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\/)?$/;

function GlobalSetting() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const settingData = async () => {
    try {
      setLoading(true);
      const response = await apiUsers.getSetting();
      setData(response.data.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    settingData();
  }, []);

  const initialValues = {
    instaLink: data?.instaLink ?? "",
    fbLink: data?.fbLink ?? "",
    contactUsEmail: data?.contactUsEmail ?? "",
  };

  const validationSchema = Yup.object().shape({
    instaLink: Yup.string()
    .trim()
    .matches(URL_REGEX, "Instagram link must be in the format: https://<domain>.com/")
    .max(100, "Instagram link must be at most 100 characters")
    .required("Instagram Link is required"),
  fbLink: Yup.string()
    .trim()
    .matches(URL_REGEX, "Facebook link must be in the format: https://<domain>.com/")
    .max(100, "Facebook link must be at most 100 characters")
    .required("Facebook Link is required"),
    contactUsEmail: Yup.string()
      .required('Contact Us Email is required')
      .trim()
      .matches(EMAIL_REGEX, "Please enter a valid email address")
      .max(255),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      values.instaLink = values?.instaLink?.trim();
      values.fbLink = values?.fbLink?.trim();
      values.contactUsEmail = values?.contactUsEmail?.trim();
      apiUsers.updateSetting(values)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res?.data?.message);
          }
          setSubmitting(false);
          settingData();
        })
        .catch((err) => {
          errorHandler(err);
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Card>
      <CardContent>
        <FormikProvider value={formik} style={{ padding: "34px 30px" }}>
          <Typography variant="h4" sx={{ mb: 2 }} align="start">
            Settings
          </Typography>
          {loading ? <CircularProgress /> : (
            <Form>
              <Stack spacing={3}>
                <TextField
                  name="instaLink"
                  label="Instagram Link *"
                  fullWidth
                  {...getFieldProps("instaLink")}
                  error={Boolean(touched.instaLink && errors.instaLink)}
                  helperText={touched.instaLink && errors.instaLink}
                />

                <TextField
                  name="fbLink"
                  label="Facebook Link *"
                  {...getFieldProps("fbLink")}
                  error={Boolean(touched.fbLink && errors.fbLink)}
                  helperText={touched.fbLink && errors.fbLink}
                />

                <TextField
                  name="contactUsEmail"
                  label="Contact Us Email *"
                  {...getFieldProps("contactUsEmail")}
                  error={Boolean(touched.contactUsEmail && errors.contactUsEmail)}
                  helperText={touched.contactUsEmail && errors.contactUsEmail}
                />
              </Stack>

              <Button size="large" type="submit" variant="contained" disabled={isSubmitting} sx={{ my: 2 }}>
                Update
              </Button>
              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  if (!isSubmitting) navigate('/dashboard');
                }}
                sx={{ my: 2, ml: 4 }}
              >
                Cancel
              </Button>
            </Form>
          )}
        </FormikProvider>
      </CardContent>
    </Card>
  );
}

export default GlobalSetting;
