import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogTitle, FormControlLabel, FormGroup,  IconButton, Input, InputAdornment, Switch, Tooltip} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { capitalize } from "../../utils/helpers";
import { STATUS_OPTIONS_LIST } from "../../utils/constants";
import TableFilter from "../../components/shared/TableFilter";
import apiOfferBannerManagement from "../../services/offerandbannerservices";
import errorHandler from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { EditOutlined } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { fDateTime } from "../../utils/formatTime";
import { Helmet } from "react-helmet-async";

export default function Offerandbannermanagement() {
  const navigate = useNavigate();
  const [searchItem, setSearchItem] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [data, setData] = useState();
  const [warning, setWarning] = useState("");
  const [action, setAction] = useState("");
  const [show, setShow] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [statusId, setStatusId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [status, setStatus] = useState([]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleClose = () => setShow(false);

  const columns = [
    {
      name: "Name",
      sortable: true,
      selector: (row) => capitalize(row.name),
    },
    {
      name: "Offer Type",
      sortable: true,
      selector: (row) => row?.offerType ==="offer" ? "Offer" : "Banner",
    },
    {
      name: "Start Date",
      sortable: true,
      selector: (row) => fDateTime(row.startDate),
    },
    {
      name: "End Date",
      sortable: true,
      selector: (row) => fDateTime(row.endDate),
    },    
    {
      name: "Status",
      cell: (row) => (
        <>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={row.status === 1}
                  onClick={() => {
                    setShow(true);
                    setCurrentStatus(row.status);
                    setStatusId(row._id);
                    setAction("status");
                    setWarning(
                      `Are you sure you want to ${
                        row.status === 1 ? "deactive" : "activate"
                      } this offer/banner ?`
                    );
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
            />
          </FormGroup>
        </>
      ),
    },
    {
      name:"Action",
      right: true,
      cell: (row) => (
        <>
         
          <Tooltip title="View" placement="top">
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/offer-and-banner-management/view/${row?._id}`);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>      
          <Tooltip title="Edit" placement="left">
            <IconButton
              color="primary"
              onClick={() => {
                navigate(`/offer-and-banner-management/edit/${row?._id}`);
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>    
          <Tooltip title="Delete" placement="right">
            <IconButton color="primary"
              onClick={() => deleteData(row?._id)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ),
    },    
  ];

  const deleteData = async (id) => {
    Swal.fire({
      // title: "Are you sure?",
      text: " Are you sure you want to delete this offer/banner ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00a1da",
      confirmButtonText: "Yes",
      cancelButtonText:"No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await apiOfferBannerManagement.deleteBanner({ id });
          if (res?.status === 200) {
            toast.success(res?.data?.message, { toastId: "success" });
            
            // Update state to remove deleted item
            setData((prevData) => prevData.filter((item) => item._id !== id));
            
          } else {
            toast.error(res?.data?.message, { toastId: "error" });
          }
        } catch (error) {
          console.error("Error deleting...", error);
          errorHandler(error);
        }
      }
    });
  };
  

  useEffect(() => {
    const body = {
      searchItem: searchItem ? searchItem : "",
      pageNumber: currentPage,
      pageSize: pageSize,
      status  : status
    };
    apiOfferBannerManagement
      .getAll(body)
      .then((res) => {
        if (res.status == 200) {
          setData(res?.data?.data?.result);
          setTotalPages(res?.data?.data?.totalRecords);
        
        }
      })
      .catch((err) => {
        errorHandler(err);
      });
  }, [searchItem, currentPage, pageSize, refresh, status]);

  
  const toggleChecked = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus ? 0 : 1; // Convert status to 0 (inactive) or 1 (active)
      
      const res = await apiOfferBannerManagement.changeStatus({
        id,
        status: newStatus, // Send 0 or 1 in payload
      });
  
      setCurrentStatus("");
  
      if (res.status === 200 && res?.data?.message) {
        toast.success(res?.data?.message, { toastId: "error1" });
        setRefresh(!refresh); // Refresh the table data after status change
        setShow(false);
      } else {
        toast.error(res?.data?.message, { toastId: "error1" });
        setShow(false);
      }
    } catch (err) {
      setShow(false);
      errorHandler(err);
    }
  };
  
  

  const handleApprove = async (id) => {};

  return (
    <Card>
      <Helmet>
        <title>Offer and Banner</title></Helmet>
      <CardHeader
        titleTypographyProps={{ variant: "h4" }}
        title="Offer And Banner Management"
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <TableFilter
          data={STATUS_OPTIONS_LIST}
          value={status}
          setValue={setStatus}
          filterName="Status"
        />
        <Input
          label="Search"
          variant="standard"
          value={searchItem}
          placeholder="Search"
          onChange={(e) => setSearchItem(e.target.value)}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="clear-search"
                onClick={() => {
                  setSearchItem("");
                }}
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <Button
          size="large"
          type="submit"
          variant="contained"
          sx={{ ml: 3, mr: 3 }}
          onClick={() => navigate("/offer-and-banner-management/add")}
        >
          Add
        </Button>
      </Box>
      <CardContent>
        <DataTable
          customStyles={{
            headCells: {
              style: {
                fontSize: "15px",
                fontWeight: "bold",
              },
            },
          }}
          columns={columns}
          data={data}
          pagination
          paginationServer
          paginationTotalRows={totalPages}
          onChangePage={handlePageChange}
          paginationPerPage={pageSize}
          // paginationTotalRows={data ? data.length : 0}
          paginationRowsPerPageOptions={[5, 10, 20, 50]}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
            setPageSize(currentRowsPerPage);
            setCurrentPage(currentPage);
          }}
        />
      </CardContent>
      <Dialog
        open={show}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle as="h2">{warning}</DialogTitle>

        <DialogActions>
          <Button
            variant="outlined"
            style={{ backgroundColor: "white" }}
            onClick={() => {
              if (action === "status") {
                toggleChecked(statusId, currentStatus); // Pass the id and current status
              }
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            style={{ backgroundColor: "white" }}
            onClick={handleClose}
          >
            No
          </Button>
        </DialogActions>

      </Dialog>
    </Card>
  );
}