import { Avatar, Button } from "@mui/material";
import {
  Box,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { ErrorMessage, Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ApiService from "../../services/categoryManagementApi";
import CardLayout from "../../layouts/CardLayout";
import errorHandler from "../../utils/errorHandler";
import { handleNumberInput } from "../../utils/CommonFunction";
import { Helmet } from "react-helmet-async";
import { LoadingButton } from "@mui/lab";
import { productValidation } from "../../utils/Schema";
import { IMAGE_BASE } from "../../utils/constants";

const { productManagementApi } = ApiService;

const AddProduct = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = useRef(null);
  const [data, setData] = useState();

  const fetchTaskData = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await productManagementApi.getById({ id });
        const resData = response?.data?.data;
        setImagePreview(IMAGE_BASE + resData?.image);
        setData(resData);
      } catch (error) {
        errorHandler(error);
      } finally {
        setLoading(false);
      }
    }
  }, [id]);

  useEffect(() => {
    fetchTaskData();
  }, [fetchTaskData]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      imagePath: "product",
      name: data?.name || "",
      qty: data?.qty || "",
      weight: data?.weight || "",
      price: data?.price || "",
      image: data?.image || "",
      description: data?.description || "",
      categoryId: data?.categoryId?._id || "",
      selectedCategoryName: data?.categoryId?.name || "",
    },
    validationSchema: productValidation,
    onSubmit: (values, { setSubmitting }) => {
      handleSumbit({ ...values }, setSubmitting);
    },
  });

  const handleSumbit = async (values, setSubmitting) => {
    try {
      // const formData = convertObjIntoFormdata(values, "image")
      const formData = new FormData();
      if (id) {
        formData.append("id", id);
      }
      formData.append("imagePath", values.imagePath);
      formData.append("name", values.name);
      formData.append("qty", values.qty);
      {
        selectedCategoryName == "Cylinder" &&
          formData.append("weight", values.weight);
      }
      formData.append("price", values.price);
      formData.append("description", values.description);
      formData.append("categoryId", values.categoryId);
      formData.append("image", values?.image);

      let res = id
        ? await productManagementApi.updateProduct(formData)
        : await productManagementApi.add(formData);
      toast.success(res?.data?.message, { toastId: "success" });
      navigate(-1);

      fileInputRef.current.value = "";
    } catch (error) {
      errorHandler(error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (data && data.categoryId) {
      setSelectedCategoryName(data.categoryId.name || "");
    }
  }, [data]);

  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];
    const validTypes = ["image/jpeg", "image/png", "image/gif"];

    if (selectedImage && selectedImage.name === file?.name) {
      toast.error("You cannot upload the same image again.");
      fileInputRef.current.value = "";
      return;
    }

    if (file && validTypes.includes(file.type)) {
      setImageLoading(true);
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file));
      formik.setFieldValue("image", file);
      setTimeout(() => {
        setImageLoading(false);
      }, 2000);
    } else {
      toast.error("Please upload a valid image (JPEG, PNG, or GIF).");
      fileInputRef.current.value = "";
    }
  };

  const { errors, touched, getFieldProps } = formik;

  const fetchAllCategories = async () => {
    try {
      const payload = {
        pageNumber: 1,
        pageSize: 5,
        searchItem: "",
        status: [],
      };
      let response = await productManagementApi.getAllCategoryForProducts(
        payload
      );
      setAllCategories(response?.data?.data?.result);
    } catch (error) {
      errorHandler(error);
    }
  };

  useEffect(() => {
    fetchAllCategories();
  }, []);

  const handleCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;
    formik.setFieldValue("categoryId", selectedCategoryId);

    const selectedCategory = allCategories.find(
      (cat) => cat._id === selectedCategoryId
    );
    const categoryName = selectedCategory?.name || "";
    setSelectedCategoryName(categoryName);
    formik.setFieldValue("selectedCategoryName", categoryName);
  };

  const { values } = formik;

  return (
    <Box>
      <Helmet>
        <title>{id ? "Edit" : "Add"} Product</title>
      </Helmet>
      <CardLayout>
        <CardHeader title={id ? "Edit Product" : "Add Product"} />
        <CardContent>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
              <Grid container spacing={3}>
              <Grid item xs={12}>
                  {/* Hidden File Input */}
                  <input
                    accept="image/*"
                    type="file"
                    ref={fileInputRef}
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />

                  {/* Image Preview and Clickable Upload Box */}
                  <Box
                    mt={2}
                    onClick={() => fileInputRef.current.click()}
                    className="upload-box"
                  >
                    {imageLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <Avatar
                        src={imagePreview || "/path/to/default-avatar.jpg"}
                        alt="preview"
                        className="avatar"
                      />
                    )}
                  </Box>

                  {/* Centered Upload Button */}
                  <Box mt={2} display="flex" justifyContent="center">
                  <Button
                        size="large"
                        variant="contained"
                        sx={{ ml: 3, mr: 3 }}
                        onClick={() => fileInputRef.current.click()}
                      >
                          {id ? "Edit" : imagePreview ? "Change" : "Upload"} Image
                      </Button>
                
                  </Box>

                  {/* Error Message */}
                  {formik.touched.image && formik.errors.image ? (
                    <div className="imageerror">
                      {formik.touched.image && formik.errors.image}
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={values?.name}
                    required
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    {...getFieldProps("name")}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{
                        color: `${
                          touched.categoryId && errors.categoryId
                            ? "#FF4842"
                            : ""
                        }`,
                      }}
                    >
                      Category *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Category *"
                      {...getFieldProps("categoryId")}
                      onChange={handleCategoryChange}
                      error={Boolean(touched.categoryId && errors.categoryId)}
                      helperText={touched.categoryId && errors.categoryId}
                    >
                      {allCategories?.map((cat, id) => (
                        <MenuItem value={cat?._id} key={id}>
                          {cat?.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {touched.categoryId && errors.categoryId && (
                    <FormHelperText error className="selectError">
                      {errors.categoryId}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Price"
                    required
                    type="text"
                    error={Boolean(touched.price && errors.price)}
                    helperText={touched.price && errors.price}
                    {...getFieldProps("price")}
                    onChange={(e) =>
                      handleNumberInput(e, "price", formik, false, 8)
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Quantity"
                    required
                    type="text"
                    error={Boolean(touched.qty && errors.qty)}
                    helperText={touched.qty && errors.qty}
                    {...getFieldProps("qty")}
                    onChange={(e) =>
                      handleNumberInput(e, "qty", formik, false, 3)
                    }
                  />
                </Grid>
                {selectedCategoryName == "Cylinder" && (
                  <Grid item xs={6}>
                    <Stack
                      alignItems="flex-start"
                      justifyContent="space-between"
                      flexDirection={"row"}
                      gap={2}
                    >
                      <TextField
                        fullWidth
                        label="Weight"
                        required
                        type="text"
                        error={Boolean(touched.weight && errors.weight)}
                        helperText={touched.weight && errors.weight}
                        {...getFieldProps("weight")}
                        onChange={(e) =>
                          handleNumberInput(e, "weight", formik, false, 5)
                        }
                      />
                    </Stack>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    label="Description"
                    required
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    {...getFieldProps("description")}
                  />
                </Grid>

                <Grid item xs={6}>
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={loading}
                  >
                    {id ? "Edit" : "Add"} Product
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </CardContent>
      </CardLayout>
    </Box>
  );
};

export default AddProduct;
